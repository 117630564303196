import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";

import {Places} from '../../../assets/jss/Data';


import TourPackages from "../../Components/Sections/TourPackages";

import basicsStyle from "../../../assets/jss/material-kit-react/views/componentsSections/basicsStyle.jsx";

class ExpSection extends React.Component {
  
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sections}>

        <div className={classes.container}>
          <div className={classes.space20} />

          <GridContainer>
              <GridItem xs={12} sm={12} md={6}>

                <div className={classes.title}>
                  <h3>Day 1</h3>
                </div>

                <ul>Meet and assist our guide on Arrival.</ul>
                <ul>Visit Pinnawala Elephant Orphanage, where is notable for having the largest herd of captive elephants in the world. You can see a wonderful sight of bathing elephants.also you can enjoy with food feeding and milk feeding.</ul>
                <ul>Leave to Sigiriya to see 650ft high rock fortress and world famous frescos painting of heavenly maidens are housed in a cave, which is declared as a World Heritage site by UNESCO. It was the largest and most sophisticated single construction in 5th century AD.</ul>
                <ul> En route visit: wood carving factory to see how to make natural painting from wood. Also silk and Batik Factories.</ul>
                <ul>Overnight stay in Sigiriya.</ul>

              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Pinnawala"].Path_1} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Polonnaruwa"].Path_2} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <div className={classes.title}>
                  <h3>Day 2</h3>
                </div>

                <ul>After breakfast travel to explore the ancient city of Polonnaruwa, once the capital of an advanced and prosperous medieval kingdom in the 2nd century BC with nice attraction lakes.Visit Parakrama Samudra and Ancient Canal, Gal Vihara, Watadege, Moon Stone, Dagaba “pagoda” and Ancient Temple complex.</ul>
                <ul>In the afternoon if you wish, take a jeep for safari, through the Kaudulla National Park/Hurulu Eco park to see Asian wild Elephants.</ul>
                <ul>In the evening visit a Srilankan Ayurwedic Spa for body relaxation and reduce mind stress.</ul>
                <ul>Overnight stay in Sigiriya.</ul>
                 
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>

                <div className={classes.title}>
                  <h3>Day 3</h3>
                </div>

                <ul>Checkout from the hotel to leave to Kandy, the Historical City and was the last kingdom of Sri Lanka via Dambulla.</ul>
                <ul>Visit Dambulla Rock Cave Temple dates going back to the 1st century BC. The rock ceiling is one large sweep of colourful frescoes which depict the tales of the Buddha’s previous births.</ul>
                <ul>En-route you can visit Spice & Ayurwedic herbal garden at Matale to see how to make natural cosmetics and Ayurwedic medicine.</ul>
                <ul>In Kandy, Visit the Temple of the Sacred Tooth Relic which is the Buddhist’s heart and the last kingdom of Sri Lanka.</ul>
                <ul>In the evening visit a spectacular cultural dance with fire walks and stay overnight in Kandy.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Dambulla"].Path_1} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Nuwara_Eliya"].Path_1} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                    <h3>Day 4</h3>
                  </div>

                  <ul>After breakfast checkout from the hotel to visit “Little England”</ul>
                  <ul>Visit Ramboda water Fall & Tunnel the nice attraction place on the way.</ul>
                  <ul>En route visit tea plantation & tea factory where you can discover how the most widely consumed drink in the world is prepared.</ul>
                  <ul>Have a city tour in Ancient English Town: where you can see an unbelievable section of top class sightseeing attraction with Anglican Church, Race course ground, Queens Park with cool climate.(If you wish you can have a boat ride or Hoarse ride at the Gregory lake).</ul>
                  <ul>Overnight stay in Hill Country of Sri Lanka.</ul>
                  
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>

                <div className={classes.title}>
                  <h3>Day 5</h3>
                </div>

                <ul>Drive to Yala, En route visit the Ella gap and Ravana Water fall which is relative to epic ramayanaya.</ul>
                <ul>Overnight stay in Yala.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Yala"].Path_1} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Galle_Fort"].Path_2} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                    <h3>Day 6</h3>
                  </div>

                  <ul>Early in the morning take a jeep to drive for the game in deep of Yala national park: to visit the most visited and second largest national park in Sri Lanka,which is best known for its variety of wild animals. It is important for the conservation Leopards, Bears, Dears, Monkeys with Sri Lankan Asian Elephants and aquatic birds.</ul>
                  <ul>After the safari checkout from the hotel and travel to Hikkaduwa.</ul>
                  <ul>En route visit The Galle Dutch Fort: which is more popular sight in Galle as the oldest fort in Asia, Named as a world heritage by the Unesco.</ul>
                  <ul>Over night stay in Hikkaduwa.</ul>
                  
              </GridItem>
        
              <GridItem xs={12} sm={12} md={6}>

                <div className={classes.title}>
                  <h3>Day 5</h3>
                </div>

                <ul>After breakfast Travel to main economical city of Colombo.</ul>
                <ul>Visit Baire Lake, Galle face green, Old parliment, Independence squire and big shopping malls.</ul>
                <ul>Leave to the airport.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Colombo"].Path_1} alt=""></img>
              </GridItem>

              
          </GridContainer>
          
          <div className={classes.space50} />
          
        </div>
        <TourPackages />
      </div>
    );
  }
}



export default withStyles(basicsStyle)(ExpSection);
