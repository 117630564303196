import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";

import {Places} from '../../../assets/jss/Data';


import TourPackages from "../../Components/Sections/TourPackages";

import basicsStyle from "../../../assets/jss/material-kit-react/views/componentsSections/basicsStyle.jsx";

class HeriSection extends React.Component {
 
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sections}>

        <div className={classes.container}>
          <div className={classes.space20} />

          <GridContainer>
              <GridItem xs={12} sm={12} md={6}>

                <div className={classes.title}>
                  <h3>Day 1</h3>
                </div>

                <ul>Meet and assist our guide on arrival.</ul>
                <ul>Travel to Anuradhapura, The First Capital of Sri Lanka in the 3rd century BC.</ul>
                <ul>Visit the first Buddhist Temple of Isurumiuniya, situated close to the Tisa Wawa. which is famous for its stone carvings of the ‘Isurumuniya Lovers’.</ul>
                <ul>There is much to visit at Anuradhapura, including the sacred tree of Sri Maha Bodhi: the oldest historical documented tree in the world, and eight major places with ancient monasteries.</ul>
                <ul>Overnight stay in Habarana.</ul>
                
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Anuradhapura"].Path_2} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Polonnaruwa"].Path_2} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 2</h3>
                </div>

                <ul>After breakfast travel to explore the ancient city of Polonnaruwa, once the capital of an advanced and prosperous medieval kingdom in the 2nd century BC with nice attraction lakes.</ul>
                <ul>Visit Parakrama Samudra and Ancient Canal, Gal Viharaya, Watadege, Moon Stone, Dagaba “pagoda” and Ancient Temple complex.</ul>
                <ul>In the afternoon take a jeep safari through the kaudulla National park/Hurul Eco Park looking for Asian Wild Elephants.</ul>
                <ul>Overnight stay in Habarana.</ul>
                 
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>

                <div className={classes.title}>
                  <h3>Day 3</h3>
                </div>

                <ul>Leave to Sigiriya to see 650ft high rock fortress and world famous frescos painting of heavenly maidens are housed in a cave, which is declared as a World Heritage site by UNESCO. It was the largest and most sophisticated single construction in 5th century AD.</ul>
                <ul>Get into the wood carving factory to see how to make the ancient natural paints from wood and see gem museum at Sigiriya.</ul>
                <ul>In the Evening you can have a relaxation and body massage from an Sri Lanka Ayurvedic Spa.</ul>
                <ul>If you have time start a village tour, This is specially recommend as a “must do activity” to feel Sri Lankan traditional rural village life.</ul>
                <ul>Overnight stay in Habarana.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Sigiriya"].Path_1} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Dambulla"].Path_1} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                    <h3>Day 4</h3>
                  </div>

                  <ul>Visit Dambulla Rock Cave Temple dates going back to the 1st century BC. The rock ceiling is one large sweep of colourful frescoes which depict the tales of the Buddha’s previous births.</ul>
                  <ul>En-route you can visit Spice & Ayurwedic herbal garden at Matale to see how to make natural cosmetics and Ayurwedic medicine.</ul>
                  <ul>View a spectacular cultural dancing show with fire walk in the evening and stay overnight in Kandy.</ul>
                  
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>

                <div className={classes.title}>
                  <h3>Day 5</h3>
                </div>

                <ul>After breakfast, visit Pinnawala Elephant Orphanage, where is notable for having the largest herd of captive elephants in the world and get into Elephant dung paper factory to visit how to make elephant dung papers.</ul>
                <ul>Visit Peradeniya Royal Botanical Gardens which houses a fine collection of over 4000 species of exotic flora plants.</ul>
                <ul>In Kandy, Visit the Temple of the Sacred Tooth Relic which is the Buddhist’s heart and the last kingdom of Sri Lanka.</ul>
                <ul>Overnight stay in Kandy.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Peradeniya"].Path_2} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Nuwara_Eliya"].Path_2} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                    <h3>Day 6</h3>
                  </div>

                  <ul>After breakfast, travel to Nuwara Eliya.</ul>
                  <ul>En route visit tea factory – to discover how the most widely consumed drink in the world is prepared.</ul>
                  <ul>Visit Gregory Lake, the most prominent attraction in Old English town. If you like to have a boat ride, Horse Ride or you can visit Hakgala botanical garden which is famous for number of species of Orchids and Roses. Walk on the lane in the city, you may feel the Ancient English colonial era.</ul>
                  <ul>Overnight stay in Hill Country of Sri Lanka.</ul>
                  
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 7</h3>
                </div>

                <ul>Early in the morning, visit Hortain plains covered by grassland and cloudy forest with world’s end and Bakers waterfall which is rich in biodiversity and many species of flora and fauna found here are endemic to the region.</ul>
                <ul>Travel to southern part of Sri Lanka.</ul>
                <ul>En route visit Ella Gap, Ravana water fall which is relative to epic Ramayanaya.</ul>
                <ul>Overnight stay in Yala</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Horton_Plains"].Path_1} alt=""></img>
              </GridItem>
              
              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Yala"].Path_2} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                    <h3>Day 8</h3>
                  </div>

                  <ul>Start for the game to visit wild animals in deep at Yala national park. You may see Elephants, Bears, Monkeys, Buff lows, Dears, Many kind of beards and Leopard in this dry zone jungle.</ul>
                  <ul>Overnight stay in Yala.</ul>
              </GridItem>
              
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 9</h3>
                </div>

                <ul>After Breakfast travel to Galle to see Ancient Dutch fort. There was a large dutch colony inner of the fort.</ul>
                <ul>Visit sea turtle hatchery.</ul>
                <ul>En route, you can enjoy at the Madu river boat safari.</ul>
                <ul>Do water activities at Benthota river.</ul>
                <ul>Overnight stay at Benthota.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Galle_Fort"].Path_2} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Colombo"].Path_1} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 10</h3>
                </div>

                <ul>Travel to main city of Colombo.</ul>
                <ul>Visit Baire lake, Independent squire, Old Parliament, Galle face green and shopping at big shopping malls.</ul>
                <ul>Leave to the air port.(If you wish).</ul>
                  
              </GridItem>


          </GridContainer>
          
          <div className={classes.space50} />
          
        </div>
        <TourPackages />
      </div>
    );
  }
}


export default withStyles(basicsStyle)(HeriSection);
