import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import Header from "../../components/Header/Header.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";

import HeaderLinks from "../../components/Header/HeaderLinks.jsx";
import Parallax from "../../components/Parallax/Parallax.jsx";
import Helmet from "react-helmet";

import { Packages } from "../../assets/jss/Data";
import { Link } from "react-router-dom";

import landingPageStyle from "../../assets/jss/material-kit-react/views/landingPage.jsx";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import TourPackages from "../Components/Sections/TourPackages";

import image1 from "../../assets/img/Home/1.jpg";
import tea from "../../assets/img/Packages/TeaCover.jpg";

class PackagesPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          brand=""
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 300,
            color: "dark",
          }}
          {...rest}
        />

        <Helmet>
          <meta charSet="utf-8" />
          <title>Tour Packages</title>
          <meta property="og:site_name" content="Summer Sri Lanka" />
          <meta
            property="og:description"
            content="The best tour Packages. visit www.summersrilanka.com for more info "
          />
          <meta property="og:image" content={image1}></meta>
          <meta
            name="keywords"
            content="sri lanaka, tour, tourim, best tour planes, places to visit in sri lanka, tour sri lanka, tour, summer, summer in sri lanka, things can do in sri lanka"
          ></meta>
          <meta
            property="og:url"
            content="http://www.summersrilanka.com/packages/"
          ></meta>
        </Helmet>

        <Parallax style={{ height: "500px" }} image={image1}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <h1 className={classes.title}>Packages</h1>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <GridContainer
              style={{ paddingTop: "100px", paddingBottom: "10px" }}
            >
              <GridItem
                xs={12}
                sm={12}
                md={4}
                style={{ paddingBottom: "80px" }}
              >
                <Link to="/around-sri-lanka">
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Packages["Around"].Cover}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Around Sri Lanka
                        </Typography>
                        <button
                          class="card-button"
                          value="Cycling"
                          onClick={this.changething}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Link>
              </GridItem>

              <GridItem
                xs={12}
                sm={12}
                md={4}
                style={{ paddingBottom: "80px" }}
              >
                <Link to="/essence-sri-lanka">
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Packages["Essence"].Cover}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Essence Sri Lanka
                        </Typography>
                        <button
                          value="Safari"
                          onClick={this.changething}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Link>
              </GridItem>

              <GridItem
                xs={12}
                sm={12}
                md={4}
                style={{ paddingBottom: "80px" }}
              >
                <Link to="/explore-sri-lanka">
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Packages["Explore"].Cover}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Explore Sri Lanka
                        </Typography>
                        <button
                          value="Surfing"
                          onClick={this.changething}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Link>
              </GridItem>

              <GridItem
                xs={12}
                sm={12}
                md={4}
                style={{ paddingBottom: "80px" }}
              >
                <Link to="/heritage-tour">
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Packages["Heritage"].Cover}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Heritage Tour
                        </Typography>
                        <button
                          value="Diving"
                          onClick={this.changething}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Link>
              </GridItem>

              <GridItem
                xs={12}
                sm={12}
                md={4}
                style={{ paddingBottom: "80px" }}
              >
                <Link to="/pristine-sri-lanka">
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Packages["Prestine"].Cover}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Pristine Sri Lanka
                        </Typography>
                        <button
                          value="Deep"
                          onClick={this.changething}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Link>
              </GridItem>

              <GridItem
                xs={12}
                sm={12}
                md={4}
                style={{ paddingBottom: "80px" }}
              >
                <Link to="/scenic-sri-lanka">
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Packages["Scenic"].Cover}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Scenic Sri Lanka
                        </Typography>
                        <button
                          value="Yoga"
                          onClick={this.changething}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Link>
              </GridItem>

              <GridItem
                xs={12}
                sm={12}
                md={4}
                style={{ paddingBottom: "80px" }}
              >
                <Link to="/tea-trail">
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia style={{ height: "200px" }} image={tea} />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Tea Trail
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Link>
              </GridItem>

              <GridItem
                xs={12}
                sm={12}
                md={4}
                style={{ paddingBottom: "80px" }}
              >
                <Link to="/contact-us">
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia style={{ height: "200px" }} image={image1} />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          More
                        </Typography>
                        <button
                          value="Jungle"
                          onClick={this.changething}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Link>
              </GridItem>
            </GridContainer>
          </div>

          <div style={{ paddingBottom: "50px" }}>
            <TourPackages />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(landingPageStyle)(PackagesPage);
