import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";

import {Packages,Places} from '../../../assets/jss/Data';


import TourPackages from "../../Components/Sections/TourPackages";

import basicsStyle from "../../../assets/jss/material-kit-react/views/componentsSections/basicsStyle.jsx";

class TeaSection extends React.Component {
  
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sections}>

        <div className={classes.container}>
          <div className={classes.space20} />

          <GridContainer>
              <GridItem xs={12} sm={12} md={6}>

                <div className={classes.title}>
                  <h3>Day 1</h3>
                </div>

                <ul>Meet and assist our guide at Bandaranaike international airport and drive to Negombo.</ul>
                <ul>Visit Fishing village, Lagoon and Ancient Dutch canal.</ul>
                <ul>In the evening you can do a boat safari over the lagoon to see magnificent view of sun set at west coast sky.</ul>
                <ul>Relax and overnight stay in beach resort.</ul>
                
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Negombo"].Path_1} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Pinnawala"].Path_2} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <div className={classes.title}>
                  <h3>Day 2</h3>
                </div>

                <ul>After breakfast travel to the last kingdom of kandy via pinnawala.</ul>
                <ul>Visit pinnawala elephant orphanage where is notable for having the largest herd of captive elephants in the world and get into Elephant dung paper factory to visit how to make elephant dung papers.</ul>
                <ul>En-route visit spice and ayurwedic spice garden to see how to make natural cosmetics and ayurwedic medicine in Sri Lanka.</ul>
                <ul>Visit Temple of the sacred tooth relic in Kandy. Its famous for daily Thevava(pooja). And in the evening see the spectacular Sri Lankan traditional cultural dancing show and fire walk.</ul>
                <ul>Overnight stay in kandy.</ul>
                 
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>

                <div className={classes.title}>
                  <h3>Day 3</h3>
                </div>

                <ul>After breakfast travel to Hill country.</ul>
                <ul>En route visit Ramboda waterfall and tunnel.</ul>
                <ul>Drive to deeper of tea plantation area to see large tea valley. You may feel the salubrious climate in hill country.</ul>
                <ul>Get in to a tea factory – to discover how the most widely consumed drink in the world is prepared.</ul>
                <ul>Visit Gregory lake, Race course ground at magnificent view in NuwaraEliya which is calling Little England.</ul>
                <ul>Overnight stay in highest city in Sri Lanka.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Packages["Tea"].Img1}alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Packages["Tea"].Img2} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                    <h3>Day 4</h3>
                  </div>

                  <ul>After breakfast start to deep explore the tea world.</ul>
                  <ul>Travel to see the ancient tea factories whish was renovated as Hotels and Restaurants with luxurious facilities going to colonial era.</ul>
                  <ul>Visit heritance tea factory and if you wish you can drink a cup of tea.</ul>
                  <ul>You can see over 50% of world’s top brand tea processing factories in Nuwara Eliya.</ul>
                  <ul>Overnight stay in Nuwara Eliya.</ul>
                  
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>

                <div className={classes.title}>
                  <h3>Day 5</h3>
                </div>

                <ul>Early In the morning start a magnificent trekking to see the World’s end and Bakers fall.This is a place to see many kind of endemic Sri Lankan birds and plants.</ul>
                <ul>After trekking leave to main economical city of Colombo.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Packages["Tea"].Img3} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Colombo"].Path_1} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                    <h3>Day 6</h3>
                  </div>

                  <ul>If you have time visit ancient places in Colombo. Baire Lake, old parliament, Galle face green.</ul>
                  <ul>Leave to the Air port..</ul>
                  
              </GridItem>

          </GridContainer>
          
          <div className={classes.space50} />
          
        </div>
        <TourPackages />
      </div>
    );
  }
}


export default withStyles(basicsStyle)(TeaSection);
