import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import carouselStyle from "../../../assets/jss/material-kit-react/views/componentsSections/carouselStyle.jsx";

import { CarouselImg } from "../../../assets/jss/Data";

class SectionCarousel extends React.Component {
  render() {
    const { classes } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <Carousel {...settings}>
            {CarouselImg.map((img_source, index) => (
              <img
                src={img_source}
                key={index}
                alt="Third slide"
                className="slick-image"
              />
            ))}
          </Carousel>
        </div>
      </div>
    );
  }
}

export default withStyles(carouselStyle)(SectionCarousel);
