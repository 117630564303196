import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "../../../node_modules/@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import Header from "../../components/Header/Header.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";

import HeaderLinks from "../../components/Header/HeaderLinks.jsx";
import Parallax from "../../components/Parallax/Parallax.jsx";

import landingPageStyle from "../../assets/jss/material-kit-react/views/landingPage.jsx";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import ButtonTheme from "../../components/CustomButtons/ButtonTheme.jsx";
import TourPackages from "../Components/Sections/TourPackages";
import Helmet from "react-helmet";

import { Places } from "../../assets/jss/Data";

class PlacesToVisit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      place: null,
    };
    this.changePlace = this.changePlace.bind(this);
  }

  changePlace = (e) => {
    this.setState({ place: e.target.value });
  };

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          brand=""
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 300,
            color: "dark",
          }}
          {...rest}
        />

        <Helmet>
          <meta charSet="utf-8" />
          <title>Places to visit</title>
          <meta property="og:site_name" content="Summer Sri Lanka" />
          <meta
            property="og:description"
            content="The best places to visit in sri lanka. visit www.summersrilanka.com for more info"
          />
          <meta property="og:image" content={Places["Galle"].Path_1}></meta>
          <meta
            name="keywords"
            content="sri lanaka, tour, tourim, best tour planes, places to visit in sri lanka, tour sri lanka, tour, summer, summer in sri lanka, things can do in sri lanka"
          ></meta>
          <meta
            property="og:url"
            content="http://www.summersrilanka.com/places-to-visit/"
          ></meta>
        </Helmet>

        {this.state.place === null ? (
          <Parallax style={{ height: "500px" }} image={Places["Galle"].Path_1}>
            <div className={classes.container}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <h1 className={classes.title}>Places to visit</h1>
                </GridItem>
              </GridContainer>
            </div>
          </Parallax>
        ) : (
          <Parallax
            style={{ height: "500px" }}
            image={Places[this.state.place].Path_1}
          >
            <div className={classes.container}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  {/* <h1 className={classes.title}>{Places[this.state.place].Topic}</h1> */}
                </GridItem>
              </GridContainer>
            </div>
          </Parallax>
        )}

        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            {this.state.place === null ? (
              <GridContainer
                style={{ paddingTop: "100px", paddingBottom: "10px" }}
              >
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Places["Colombo"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Colombo
                        </Typography>
                        <button
                          value="Colombo"
                          onClick={this.changePlace}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Places["Pinnawala"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Pinnawala
                        </Typography>
                        <button
                          value="Pinnawala"
                          onClick={this.changePlace}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Places["Sigiriya"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Sigiriya
                        </Typography>
                        <button
                          value="Sigiriya"
                          onClick={this.changePlace}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Places["Dambulla"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Dambulla
                        </Typography>
                        <button
                          value="Dambulla"
                          onClick={this.changePlace}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Places["Kandy"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Kandy
                        </Typography>
                        <button
                          value="Kandy"
                          onClick={this.changePlace}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Places["Nuwara_Eliya"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Nuwara Eliya
                        </Typography>
                        <button
                          value="Nuwara_Eliya"
                          onClick={this.changePlace}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Places["Anuradhapura"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Anuradhapura
                        </Typography>
                        <button
                          value="Anuradhapura"
                          onClick={this.changePlace}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Places["Horton_Plains"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Horton Plains
                        </Typography>
                        <button
                          value="Horton_Plains"
                          onClick={this.changePlace}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Places["Negombo"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Negombo
                        </Typography>
                        <button
                          class="card-button"
                          value="Negombo"
                          onClick={this.changePlace}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Places["Polonnaruwa"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Polonnaruwa
                        </Typography>
                        <button
                          value="Polonnaruwa"
                          onClick={this.changePlace}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Places["Peradeniya"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Peradeniya Botanical Garden
                        </Typography>
                        <button
                          value="Peradeniya"
                          onClick={this.changePlace}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Places["Galle_Fort"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Galle Fort
                        </Typography>
                        <button
                          value="Galle_Fort"
                          onClick={this.changePlace}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Places["Mirissa"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Mirissa
                        </Typography>
                        <button
                          value="Mirissa"
                          onClick={this.changePlace}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Places["Hikkaduwa"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Hikkaduwa
                        </Typography>
                        <button
                          value="Hikkaduwa"
                          onClick={this.changePlace}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Places["Galle"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Galle
                        </Typography>
                        <button
                          value="Galle"
                          onClick={this.changePlace}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Places["Kithulgala"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Kithulgala
                        </Typography>
                        <button
                          value="Kithulgala"
                          onClick={this.changePlace}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Places["Yala"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Yala
                        </Typography>
                        <button
                          value="Yala"
                          onClick={this.changePlace}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Places["Trincomalee"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Trincomalee
                        </Typography>
                        <button
                          value="Trincomalee"
                          onClick={this.changePlace}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>
              </GridContainer>
            ) : (
              <GridContainer
                style={{ paddingTop: "100px", paddingBottom: "100px" }}
              >
                <GridItem xs={12} sm={12} md={6}>
                  <h2 style={{ color: "black" }}>
                    {Places[this.state.place].Topic}
                  </h2>
                  <p style={{ color: "black" }}>
                    {Places[this.state.place].Description}
                  </p>
                  <ButtonTheme color="primary" href="/places-to-visit">
                    Back
                  </ButtonTheme>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <img
                    className="themeImage"
                    src={Places[this.state.place].Path_2}
                    alt=""
                  ></img>
                </GridItem>
              </GridContainer>
            )}
          </div>

          <div style={{ paddingBottom: "50px" }}>
            <TourPackages />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(landingPageStyle)(PlacesToVisit);
