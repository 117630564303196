import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import "./assets/scss/material-kit-react.scss";
//style
import "./App.css";
//Pages
import Home from "./views/LandingPage/Home.jsx";
import ContactUs from "./views/LandingPage/Contact.jsx";
import FeedBacks from "./views/LandingPage/FeedBacks.jsx";
import Packages from "./views/LandingPage/Packages.jsx";
import PlacesToVisit from "./views/LandingPage/PlacesToVisit.jsx";
import ThingsToDo from "./views/LandingPage/ThingsToDo.jsx";
import TravelTips from "./views/LandingPage/TravelTips.jsx";

//packages
import Tea from "./views/LandingPage/TeaTrail.jsx";
import Around from "./views/LandingPage/AroundSL.jsx";
import Explore from "./views/LandingPage/Explore.jsx";
import Heritage from "./views/LandingPage/Heritage.jsx";
import Essence from "./views/LandingPage/Essence.jsx";
import Scenic from "./views/LandingPage/Scenic.jsx";
import Pristine from "./views/LandingPage/Pristine.jsx";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/places-to-visit" component={PlacesToVisit} />
      <Route path="/things-to-do" component={ThingsToDo} />
      <Route path="/packages" component={Packages} />
      <Route path="/travel-tips" component={TravelTips} />
      <Route path="/guest-feedback" component={FeedBacks} />
      <Route path="/contact-us" component={ContactUs} />

      <Route path="/tea-trail" component={Tea} />
      <Route path="/around-sri-lanka" component={Around} />
      <Route path="/explore-sri-lanka" component={Explore} />
      <Route path="/heritage-tour" component={Heritage} />
      <Route path="/essence-sri-lanka" component={Essence} />
      <Route path="/scenic-sri-lanka" component={Scenic} />
      <Route path="/pristine-sri-lanka" component={Pristine} />

      <Route path="/" component={Home} />
    </Switch>
  </Router>,

  document.getElementById("root")
);
