import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Notes from "../../../assets/img/notes.jpg";
import Coins from "../../../assets/img/Coins.jpg";

import TourPackages from "../../Components/Sections/TourPackages";

import basicsStyle from "../../../assets/jss/material-kit-react/views/componentsSections/basicsStyle.jsx";

class SectionBasics2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sections}>
        <div className={classes.container}>
          <div className={classes.space20} />

          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.title}>
                <h3>Sri Lanka Basic Information</h3>
              </div>

              <ul>Capitals :Sri Jayewardenepura Kotte.</ul>
              <ul>Dialling code : +94</ul>
              <ul>Currency : Sri Lankan Rupee (LKR)</ul>
              <ul>Time Difference: GMT +5.30 hour.</ul>
              <ul>Location : Latitude 5-10(North)/ Longitude 79-82 (East)</ul>
              <ul>Nationalities: Sinhalese (Main), Tamils, Muslims, Burger</ul>
              <ul>
                You are arriving at the Bandaranaike International Airport, 36
                km north of Colombo.
              </ul>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.title}>
                <h3>Sri Lanka Visa</h3>
              </div>

              <p>
                A Tourist Visa is issued for tourists who want to enter Sri
                Lanka for sightseeing, excursions, relaxation, visit relatives
                or yoga training for a short period of time.
              </p>
              <p>Contact details: Department of Immigration and Emigration</p>
              <p>www.immigration.gov.lk</p>
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.title}>
                <h3>Sri Lanka Money – Notes</h3>
              </div>

              <img className="notes" src={Notes} alt=""></img>
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.title}>
                <h3>Sri Lanka Money – Coins</h3>
              </div>

              <img className="coins" src={Coins} alt=""></img>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <div className={classes.title}>
                <h3>Travel Information</h3>
              </div>

              <ul>
                <b>If you wish to use a mobile phone</b> you can buy a local SIM
                card for around Rs. 200/= You will find recharge cards at any
                communication throughout the island.
              </ul>
              <ul>
                <b>In Sri Lanka there is no free Wifi services</b> but you can
                use free WI-FI only in three star & above hotels and also in
                colombo area.
              </ul>
              <ul>
                <b>Mother language</b> is Sinhala and lot of people have good
                English knowledge as a second language. Some part of people
                speak Tamil.
              </ul>
              <ul>
                <b>The island has two wet moon seasons</b> briefly between May
                and July in the south-west region and between December and
                January in the north-east.
              </ul>
              <ul>
                <b>The average temperature</b> is around 27°C – 30°C in most
                parts of island. In the mountains around Nuwara Eliya it gets
                quite cool, especially at night 15°C – 10°C.
              </ul>
              <ul>
                <b>Foreign currency</b> and Traveller’s Cheques may be changed
                at any hotel, although commercial banks offer better rates.
                Banks are generally open from 00900 hrs to1500 hrs from Monday
                to Friday. Some city banks close at 6.00pm, some other banks
                offer night banking facilities. Most banks are closed on
                Saturday and Sunday, all public holidays and special bank
                holidays.
              </ul>
              <ul>
                <b>ATM in Sri Lanka;</b> ATMs are found in banks island-wide.
                Most hotels, restaurants and shopping centres accept credit
                cards Visa and Master Cards.
              </ul>
              <ul>
                <b>Pharmacies and medical centers</b> are available in all
                towns. But it is better to bring enough of your prescription
                medicines too as the brand might not be stocked by local
                pharmacies.
              </ul>
              <ul>
                <b>Takin pctures;</b> You need to get permission before taking
                photos in some important places. Specially in cultural areas.
                You should remember it is prohibited to have your photo taken
                with your back to a statue of Lord Buddha.
              </ul>
              <ul>
                <b>Cotton clothes are useful at any time of the year.</b> Light
                loose cotton clothing is advisable. In places of worship or of
                cultural importance showing shoulders and legs (above the knee)
                is frowned upon, that includes wearing short trousers. A proper
                dress for men and women is advisable especially in religious
                sites. You will also be required to remove your footwear and
                headware.Try to use comfortable shoes or sandals.
              </ul>
              <ul>
                <b>Driving time;</b> When organize a Round Trip Tour of Sri
                Lanka, the flight arrival time is an important component to make
                maximum use of your stay. Habarana is a central point when
                visiting cultural sites such as Anuradhapura, Polonnaruwa,
                Sigiriya, and Dambulla and is approximately four hours away from
                the airport. Most travellers prefer to drive up to Habarana
                directly from the airport which is possible when your flight
                arrives in the morning. For flights that arrive in the evening,
                the best option is to stay in Negombo (about 20 minutes drive
                from the airport) for the first night or longer.
              </ul>
              <ul>
                <b>Voltage</b> in Sri Lanka we are using 220 Volts/50 Hz Ac main
                electricity supply. Two and three pin round and square plugs are
                used. 5A, 10A, 15A.
              </ul>
              <ul>
                <b>Customs;</b> You may be required to open your baggage for
                inspection on departure as well as on arrival. Tobacco products
                are not part of a tourist’s duty free allowance. Certain types
                of animal products, antiques and gold are not allowed to be
                taken out of the country. It is important that you retain your
                shopping receipts, especially for gems. You are not allowed to
                take endemic plants, Animals with you, and keep your all
                purchasing items bills till you leave from Sri Lanka.
              </ul>
              <ul>
                <b>Sri Lanka Shops</b> Most shops open at 09.00am and close at
                06.00pm. Shops are usually closed on Sundays and Full moon
                (Poya) days. The full moon Poya day has religious significance
                for Buddhists and alcohol is not served in hotels, bars,
                restaurants and public recreational areas on this day.
              </ul>
              <ul>
                <b>
                  You are not allowed to take endemic plants and animals with
                  you.
                </b>{" "}
                Keep your purchasing bills till you leave Sri Lanka
              </ul>
            </GridItem>
          </GridContainer>

          <div className={classes.space50} />
        </div>
        <TourPackages />
      </div>
    );
  }
}

export default withStyles(basicsStyle)(SectionBasics2);
