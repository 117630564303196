import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardBody from "../../../components/Card/CardBody.jsx";
import image1 from "../../../assets/img/Home/bg6.jpg";
import image2 from "../../../assets/img/Home/bg7.jpg";
import image3 from "../../../assets/img/Home/bg8.jpg";

import TourPackages from "./TourPackages.jsx";

import basicsStyle from "../../../assets/jss/material-kit-react/views/componentsSections/basicsStyle.jsx";

class SectionBasics extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sections}>
        <div className={classes.container}>
          <div className={classes.title}>
            <h1>Best Sri Lanka Tour</h1>
          </div>

          <div className={classes.space50} />

          <GridContainer>
            <GridItem xs={12} sm={12} md={4} style={{ paddingBottom: "80px" }}>
              <Card className={classes.card}>
                <CardMedia style={{ height: "200px" }} image={image1} />
                <CardBody>
                  <p>
                    Summer Sri Lanka is the best way to make arrange your
                    holiday vacation. We promise to give the best value for your
                    money. Our chauffeurs and guides have specialized knowledge
                    to make unforgettable memories in the miracle island.
                  </p>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={4} style={{ paddingBottom: "80px" }}>
              <Card className={classes.card}>
                <CardMedia style={{ height: "200px" }} image={image2} />
                <CardBody>
                  <p>
                    We are especially thinking to provide Eco-friendly
                    transfers. We have a strong vehicle fleet to travel with you
                    for the ultimate destination on this small island. Visit us
                    today for a different experience.
                  </p>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={4} style={{ paddingBottom: "80px" }}>
              <Card className={classes.card}>
                <CardMedia style={{ height: "200px" }} image={image3} />
                <CardBody>
                  <p>
                    We are ready to make the reservations in luxury hotels at
                    all destinations. You can choose your choice from our hotel
                    networks.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>

        <TourPackages />

        <div className={classes.space50} />

        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.title}>
                <h2>Sri Lanka</h2>
              </div>

              <p>
                The island with blooming flowers of joviality and enjoy the
                nature its best, where the grass is the greenest. Begin your
                life in another SPRING that comes back to life and everything
                blooms. Relax your mindset in a place where majestic elephants
                amble up to rub its' scratching back against a tree trunk.
                Listen to the whisper of a stream in the valley, the songs of
                the wild and the far-away call of a rolling waterfall.
              </p>
              <p>
                Dream on a cool mountain top with your eyes wide open, enjoy the
                panorama of rolling downs when you pass the tea hills. Observe
                the dew misting in the proud written history of the miracle
                island dates going back to 6th century BC. You will amaze when
                visiting the 1st and 2nd capitals in the ancient paradise called
                "Sinhale”.Glimpse at the 8th wonder citadel at Sigiriya when you
                come to the last kingdom, known as Kandy in the colonel Era.
                Endless west coast sandy beaches may make unforgettable memories
                and feel the relaxation in body, mind, and soul. We invite you
                to dip into the resplendent land with the highest recorded
                biodiversity.
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <div style={{ paddingTop: "50px" }}>
                <iframe
                  className="youtube_vid"
                  src="https://www.youtube.com/embed/oEScbnv4waw"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="home_video"
                ></iframe>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(SectionBasics);
