import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Button from "../../../components/CustomButtons/Button.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CardFooter from "../../../components/Card/CardFooter.jsx";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { sendEmail } from "../../../routes/actions";
import { Phone } from "@material-ui/icons";

import basicsStyle from "../../../assets/jss/material-kit-react/views/componentsSections/basicsStyle.jsx";

class SectionBasics3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  details = {
    name: null,
    email: null,
    phone: null,
    country: null,
    subject: null,
    arrival_date: null,
    notes: null,
  };

  handleChange(event) {
    this.details[event.target.id] = event.target.value;
  }

  send = async () => {
    this.setState({ success: false });
    if (this.details.name !== null) {
      try {
        await sendEmail(this.details);
        this.setState({ success: true });
      } catch (error) {}
    } else {
      this.setState({ success: false });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sections}>
        <div className={classes.container}>
          <div className={classes.space20} />

          <GridContainer>
            <GridItem xs={12} sm={12} md={9}>
              <Card className={classes[this.state.cardAnimaton]}>
                <form className={classes.form}>
                  <CardBody>
                    <FormControl
                      className={classes.formControl}
                      fullWidth
                      style={{ marginTop: "20px" }}
                    >
                      <InputLabel htmlFor="component-simple">
                        Your Name (required)
                      </InputLabel>
                      <Input
                        id="name"
                        onChange={this.handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <Icon>people</Icon>
                          </InputAdornment>
                        }
                      />
                    </FormControl>

                    <FormControl
                      className={classes.formControl}
                      fullWidth
                      style={{ marginTop: "30px" }}
                    >
                      <InputLabel htmlFor="component-simple">
                        Your Email (required)
                      </InputLabel>
                      <Input
                        id="email"
                        onChange={this.handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <Icon>mail</Icon>
                          </InputAdornment>
                        }
                      />
                    </FormControl>

                    <FormControl
                      className={classes.formControl}
                      fullWidth
                      style={{ marginTop: "30px" }}
                    >
                      <InputLabel htmlFor="component-simple">
                        Your Phone Number(required)
                      </InputLabel>
                      <Input
                        id="phone"
                        onChange={this.handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <Icon>phone</Icon>
                          </InputAdornment>
                        }
                      />
                    </FormControl>

                    <FormControl
                      className={classes.formControl}
                      fullWidth
                      style={{ marginTop: "30px" }}
                    >
                      <InputLabel htmlFor="component-simple">
                        Country
                      </InputLabel>
                      <Input
                        id="country"
                        onChange={this.handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <Icon>place</Icon>
                          </InputAdornment>
                        }
                      />
                    </FormControl>

                    <FormControl
                      className={classes.formControl}
                      fullWidth
                      style={{ marginTop: "30px" }}
                    >
                      <InputLabel htmlFor="component-simple">
                        Subject
                      </InputLabel>
                      <Input
                        id="subject"
                        onChange={this.handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <Icon>info</Icon>
                          </InputAdornment>
                        }
                      />
                    </FormControl>

                    <FormControl
                      className={classes.formControl}
                      fullWidth
                      style={{ marginTop: "30px" }}
                    >
                      <InputLabel htmlFor="component-simple">
                        Expecting arrival date:{" "}
                      </InputLabel>
                      <Input
                        style={{ paddingLeft: "180px" }}
                        id="arrival_date"
                        onChange={this.handleChange}
                        type="date"
                      />
                    </FormControl>

                    <FormControl
                      className={classes.formControl}
                      fullWidth
                      style={{ marginTop: "30px" }}
                    >
                      <InputLabel htmlFor="component-simple">
                        Your notes
                      </InputLabel>
                      <Input
                        id="notes"
                        onChange={this.handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <Icon>note</Icon>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </CardBody>

                  <CardFooter className={classes.cardFooter}>
                    <Button
                      simple
                      color="primary"
                      size="lg"
                      onClick={this.send}
                    >
                      {" "}
                      Send us
                    </Button>

                    {this.state.success === true ? (
                      <div>
                        <h5>
                          Thank you! Your message has been sent successfully..
                        </h5>
                      </div>
                    ) : null}
                  </CardFooter>
                </form>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={3}>
              <div className={classes.title}>
                <h3>Contact us</h3>
                <br />

                <h4>Mr. Vasantha Pradeep</h4>
                <h5>
                  788/1/1, Peradeniya Road, Getambe, Kandy, Sri Lanka 20000
                </h5>
                <h5>summersltours@gmail.com</h5>
                <h5>www.summersrilanka.com</h5>
                <h5>
                  <Phone style={{ fontSize: "15" }} /> : +94 755 800808 <br />
                  <Phone style={{ fontSize: "15" }} /> : +94 728 416416
                </h5>
                <br />
                <hr />
              </div>
            </GridItem>
          </GridContainer>

          <div className={classes.space50} />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(SectionBasics3);
