import React from "react";

import classNames from "classnames";
import withStyles from "../../../node_modules/@material-ui/core/styles/withStyles";
import Header from "../../components/Header/Header.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Helmet from "react-helmet";
import HeaderLinks from "../../components/Header/HeaderLinks.jsx";
import Parallax from "../../components/Parallax/Parallax.jsx";

import landingPageStyle from "../../assets/jss/material-kit-react/views/landingPage.jsx";

import SectionBasics from "./Sections/SectionBasics2";

import image1 from "../../assets/img/Home/9.jpg";

class TravelTips extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          brand=""
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 300,
            color: "dark",
          }}
          {...rest}
        />

        <Helmet>
          <meta charSet="utf-8" />
          <title>Travel Tips</title>
          <meta property="og:site_name" content="Summer Sri Lanka" />
          <meta
            property="og:description"
            content="Travel tips to help first-time visitors. visit www.summersrilanka.com for more info "
          />
          <meta property="og:image" content={image1}></meta>
          <meta
            name="keywords"
            content="sri lanaka, tour, tourim, best tour planes, places to visit in sri lanka, tour sri lanka, tour, summer, summer in sri lanka, things can do in sri lanka"
          ></meta>
          <meta
            property="og:url"
            content="http://www.summersrilanka.com/travel-tips/"
          ></meta>
        </Helmet>

        <Parallax style={{ height: "500px" }} image={image1}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <h1 className={classes.title}>Travel Tips</h1>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <SectionBasics />
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(landingPageStyle)(TravelTips);
