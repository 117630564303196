import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";

import {Packages,Places} from '../../../assets/jss/Data';


import TourPackages from "../../Components/Sections/TourPackages";

import basicsStyle from "../../../assets/jss/material-kit-react/views/componentsSections/basicsStyle.jsx";
class EssenceSection extends React.Component {
 
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sections}>

        <div className={classes.container}>
          <div className={classes.space20} />

          <GridContainer>
              <GridItem xs={12} sm={12} md={6}>

                <div className={classes.title}>
                  <h3>Day 1</h3>
                </div>

                <ul>Meet and assist our guide on Arrival. </ul>
                <ul>Travel to Negombo (20minutes).</ul>
                <ul>Leisure and Overnight stay, beach resort at Negombo.</ul>
                
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <img className="themeImage" src={Places["Negombo"].Path_1} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <img className="themeImage" src={Packages["Essence"].Img1} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 2</h3>
                </div>

                <ul>After breakfast drive to Nallathanniya, entrance of the Adam’s peak.</ul>
                <ul>People believe that this is the place of Lord Buddha has been marked foot print in according the invitation of God Saman.</ul>
                <ul>Check in to the hotel at Nallathanniya.</ul>
                <ul>Start to climb Sri Pada (Adam’s peak) at night.</ul>
                 
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>

                <div className={classes.title}>
                  <h3>Day 3</h3>
                </div>

                <ul>Early in the morning see the sun rise at scenic view.</ul>
                <ul>Quickly steps to the hotel.</ul>
                <ul>Relax at the hotel in Nallathanniya.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Packages["Essence"].Img2} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <img className="themeImage" src={Packages["Tea"].Img1} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                    <h3>Day 4</h3>
                  </div>

                  <ul>After breakfast travel to deep of tea plantations.</ul>
                  <ul>En route visit a tea factory – to discover how the most widely consumed drink in the world is prepared.</ul>
                  <ul>Visit Gregory Lake, the most prominent attraction in Old English town. If you like to have a boat ride, Horse Ride. When you walk on the lane in the city, you may feel the Ancient English colonial era.</ul>
                  <ul>Overnight stay in Hill Country of Nuwara Eliya.</ul>
                  
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>

                <div className={classes.title}>
                  <h3>Day 5</h3>
                </div>

                <ul>(Take away breakfast) Early in the morning travel to Horton Plains National Park which is a key wildlife area of open grassy woodland and World’s end with Bakers water fall. Collect more photographs of flora & fauna with many more endemic spices.</ul>
                <ul>After the trekking proceed to Yala.</ul>
                <ul>Explore Ella gap and Rawana Waterfall which was relative to prehistoric legend of Ramayana.</ul>
                <ul>Drive to southern part of Sri Lanka, overnight stay at Thissamaharama.</ul>
                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Horton_Plains"].Path_2} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Yala"].Path_2} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                    <h3>Day 6</h3>
                  </div>

                  <ul>Early in the morning start for the game to visit wild animals in deep at Yala national park. You may see Elephants, Bears, Monkeys, Buff lows, Dears, Many kind of beards and Leopard in this dry zone jungle.</ul>
                  <ul>Overnight stay in Yala.</ul>
                  
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 7</h3>
                </div>

                <ul>After breakfast travel to Unawatuna.</ul>
                <ul>Get in to sea Turtle hatchery and visit the Galle fort.</ul>
                <ul>Overnight stay at beach resort in Unawatuna.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Galle"].Path_2} alt=""></img>
              </GridItem>
              
              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Packages["Essence"].Img3} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                    <h3>Day 8</h3>
                  </div>

                  <ul>After breakfast travel to visit Japan peace pagoda and Rumassala herbal jungle.</ul>
                  <ul>Relax and overnight stay in Unawatuna.</ul>
              </GridItem>
              
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 9</h3>
                </div>

                <ul>After breakfast travel to Benthota.</ul>
                <ul>Benthota is famous for water activities at lagoon, river and sea.</ul>
                <ul>Relax and overnight stay in Benthota.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Packages["Around"].Img1} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Colombo"].Path_1} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 10</h3>
                </div>

                <ul>Travel to the main economical city of Colombo after checkout from the hotel.</ul>
                <ul>Have a city tour in Colombo to see big shopping malls, Old parliament, Independence Squire, Galle face green, Baire lake and Gangarama temple.</ul>
                <ul>Leave to the airport.</ul>
                  
              </GridItem>

          </GridContainer>
          
          <div className={classes.space50} />
          
        </div>
        <TourPackages />
      </div>
    );
  }
}


export default withStyles(basicsStyle)(EssenceSection);
