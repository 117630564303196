import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";


import {Packages,Places} from '../../../assets/jss/Data';


import TourPackages from "../../Components/Sections/TourPackages";

import basicsStyle from "../../../assets/jss/material-kit-react/views/componentsSections/basicsStyle.jsx";

class AroundSection extends React.Component {
 
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sections}>

        <div className={classes.container}>
          <div className={classes.space20} />

          <GridContainer>

              
              <GridItem xs={12} sm={12} md={6}>

                <div className={classes.title}>
                  <h3>Day 1</h3>
                </div>

                <ul>Meet and assist our guide on Arrival. </ul>
                <ul>Drive to visit the fishing village and Ancient Dutch fort in Negombo. Get a boat in the evening to ride over lagoon.</ul>
                <ul>Leisure and Overnight stay, beach resort in Negombo.</ul>
                
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                  <img className="themeImage" src={Places["Negombo"].Path_1} alt=""></img>
              </GridItem>
 
              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Anuradhapura"].Path_2} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 2</h3>
                </div>

                <ul>Travel to Anuradhapura, The First Capital of Sri Lanka in the 3rd century BC to visit the first Buddhist Temple of Isurumiuniya, situated close to the Tisa Wawa. Which is famous for its stone carvings of the ‘Isurumuniya Lovers’.</ul>
                <ul>There is much to visit at Anuradhapura, including the sacred tree of Sri Maha Bodhi: the oldest historical documented tree in the world, and eight major places with ancient monasteries.</ul>
                <ul>In the evening visit Sri Lankan traditional wood carvings and natural painting techniques.</ul>
                <ul>Overnight stay in Sigiriya.</ul>
                 
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>

                <div className={classes.title}>
                  <h3>Day 3</h3>
                </div>

                <ul>After breakfast explore the ancient city of Polonnaruwa, once the capital of an advanced and prosperous medieval kingdom, which is full of stone sculptures in 2nd century BC.</ul>
                <ul>Visit “Gal Viharaya”main attraction in Polonnaruwa, has two seating Buddha statues, one Standing statue and one statue in a ParinirvanaManchakaya which were done from a single huge granite rock.</ul>
                <ul>Evening take a jeep for safari to see Asian Elephants at Minneriya/ Habarana National park.</ul>
                <ul>Overnight stay in Sigiriya.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Polonnaruwa"].Path_2} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Sigiriya"].Path_1} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                    <h3>Day 4</h3>
                  </div>

                  <ul>After breakfast drive to east coast beach in Sri Lanka.</ul>
                  <ul>Visit Thirukoneshwaram hindu temple. This is a most famous place in Hindu culture and see Kinniya hot water spring wells.There are 07 wells with different temperature in each one.</ul>
                  <ul>Leave to Sigiriya to see 650ft high rock fortress and world famous frescos painting of heavenly maidens are housed in a cave, which is declared as a World Heritage site by UNESCO. It was the largest and most sophisticated single construction in 5th century AD.</ul>
                  <ul>When you are at Sigiriya do a village tour, this is recommend as a “must do activity”. You can feel the Sri Lankan traditional rural village life.</ul>
                  <ul>In the Evening you can have a relaxation and body massage from an Ayurvedic Spa.</ul>
                  <ul>Overnight stay in Sigiriya.</ul>
                  
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>

                <div className={classes.title}>
                  <h3>Day 5</h3>
                </div>

                <ul>Visit rock temple of Dambulla Major attractions of the area contain the largest and best conserved Cave Temple Complex of Sri Lanka dates going back to 1st century BC.</ul>
                <ul>Leave to Kandy.</ul>
                <ul>En route visit a spice and ayurwedic herbal garden at Mathale.</ul>
                <ul>In Kandy, have a city tour in last kingdom of Sri Lanka: Highlights: The Temple of the Sacred Tooth Relic which is the Buddhist’s heart, Kandy Lake, Gem museum.</ul>
                <ul>Overnight stay in Kandy.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Dambulla"].Path_1} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Pinnawala"].Path_2} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                    <h3>Day 6</h3>
                  </div>

                  <ul>After breakfast, visit Pinnawala Elephant Orphanage, where is notable for having the largest herd of captive elephants in the world and get into Elephant dung paper factory to visit how to make elephant dung papers.</ul>
                  <ul>Visit Peradeniya Royal Botanical Gardens which houses a fine collection of over 4000 species of exotic flora plants.</ul>
                  <ul>Overnight stay in Kandy.</ul>
                  
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 7</h3>
                </div>

                <ul>After breakfast, travel to Nuwara Eliya.</ul>
                <ul>En route visit tea factory – to discover how the most widely consumed drink in the world is prepared.</ul>
                <ul>Visit Gregory Lake, the most prominent attraction in Old English town. If you like to have a boat ride, Horse Ride or you can visit Hakgala botanical garden which is famous for number of species of Orchids and Roses. Walk on the lane in the city, you may feel the Ancient English colonial era.</ul>
                <ul>Overnight stay in Hill Country of Sri Lanka</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Nuwara_Eliya"].Path_2} alt=""></img>
              </GridItem>
              
              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Horton_Plains"].Path_1} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                    <h3>Day 8</h3>
                  </div>

                  <ul>Early in the morning travel to Horton Plains National Park which is a key wildlife area of open grassy woodland and World’s end with Bakers water fall. Collect more photographs of flora & fauna with many more endemic spices.</ul>
                  <ul>Get in to train tour to see nice attractions from highest railway station to Ella.</ul>
                  <ul>Explore Ella gap and Rawana Waterfall whish was relative to prehistoric legend.</ul>
                  <ul>Drive to southern part of Sri Lanka, overnight stay at beach resort in Mirissa.</ul>
              </GridItem>
              
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 9</h3>
                </div>

                <ul>Early in the morning check out from hotel to Whales and Dolphin watching in Mirissa. This is a famous deep sea area to see Blue whales.</ul>
                <ul>Travel to Galle.</ul>
                <ul>En route visit Unawatuna Beach which is the one of most beautiful beach in the world.</ul>
                <ul>Get in to sea Turtle hatchery and visit the Galle fort.</ul>
                <ul>Overnight stay at beach resort in Benthota.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Mirissa"].Path_1} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Packages["Around"].Img1} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 10</h3>
                </div>

                <ul>Benthota is famous for water activities at lagoon, river and sea.</ul>
                <ul>Leisure at the beach resort in Benthota.</ul>
                  
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 11</h3>
                </div>

                <ul>Travel to the main economical city of Colombo after checkout from the hotel.</ul>
                <ul>Have a city tour in Colombo to see big shopping malls, Old parliament, Independent Squire, Galle face green.</ul>
                <ul>Leave to the airport.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Colombo"].Path_1} alt=""></img>
              </GridItem>

          </GridContainer>
          
          <div className={classes.space50} />
          
        </div>
        <TourPackages />
      </div>
    );
  }
}


export default withStyles(basicsStyle)(AroundSection);
