import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";

import {Packages,Places} from '../../../assets/jss/Data';
import nineArch from "../../../assets/img/Home/7.jpg";



import TourPackages from "../../Components/Sections/TourPackages";

import basicsStyle from "../../../assets/jss/material-kit-react/views/componentsSections/basicsStyle.jsx";

class PristineSection extends React.Component {
 
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sections}>

        <div className={classes.container}>
          <div className={classes.space20} />

          <GridContainer>
              <GridItem xs={12} sm={12} md={6}>

                <div className={classes.title}>
                  <h3>Day 1</h3>
                </div>

                <ul>Meet and assist our guide on Arrival. </ul>
                <ul>Drive to visit the fishing village and Ancient Dutch canal in Negombo. If you like get a boat in the evening to ride over lagoon to see sunset.</ul>
                <ul>Leisure and Overnight stay, beach resort in Negombo.</ul>
                
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Negombo"].Path_1} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Anuradhapura"].Path_2} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 2</h3>
                </div>

                <ul>After the breakfast travel to Anuradhapura, The First Capital of Sri Lanka in the 3rd century BC.</ul>
                <ul>Visit the first Buddhist Temple of Isurumiuniya, situated close to the Tisa Wawa. which was famous for its stone carvings of the ‘Isurumuniya Lovers’.</ul>
                <ul>There is much to visit at Anuradhapura, including the sacred tree of Sri Maha Bodhi: the oldest historical documented tree in the world, and eight major places with ancient monasteries.</ul>
                <ul>Overnight stay in Anuradhapura.</ul>
                 
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>

                <div className={classes.title}>
                  <h3>Day 3</h3>
                </div>

                <ul>After breakfast travel to central province.</ul>
                <ul>En-route get into Ritigala histirical monument area.</ul>
                <ul>This place is famous for legend Ramayana, dates going back to 50th century BC.</ul>
                <ul>Overnight stay in Sigiriya.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Packages["Prestine"].Img1} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Polonnaruwa"].Path_2} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                    <h3>Day 4</h3>
                  </div>

                  <ul>After breakfast travel to explore the ancient city of Polonnaruwa (2nd capital in ancient Sri Lanka), once the capital of an advanced and prosperous medieval kingdom, which is full of stone sculptures in 2nd century BC.</ul>
                  <ul>Visit Watadage and “Gal Viharaya”main attraction in Polonnaruwa, has two seating Buddha statues, one Standing statue and one statue in a ParinirvanaManchakaya which were done from a single huge granite rock.</ul>
                  <ul>Leave to Sigiriya to see 650ft high rock fortress and world famous frescos painting of heavenly maidens are housed in a cave, which is declared as a World Heritage site by UNESCO. It was the largest and most sophisticated single construction in 5th century AD.</ul>
                  <ul>Overnight stay in Sigiriya.</ul>
                  
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>

                <div className={classes.title}>
                  <h3>Day 5</h3>
                </div>

                <ul>After breakfast checkout from the hotel to visit Dambulla Rock Cave Temple dates going back to the 1st century BC. The rock ceiling is one large sweep of colourful frescoes which depict the tales of the Buddha’s previous births.</ul>
                <ul>Leave to Kandy, En-route visit Spice and herbal garden at Mathale to visit how to make natural ayurvedic medicine and natural cosmetics in Sri Lanka.</ul>
                <ul>In the evening you can visit spectacular cultural dancing show and fire walk.</ul>
                <ul>When you at the last kingdom of Kandy, Visit the Temple of the Sacred Tooth Relic which is the Buddhist’s heart.</ul>
                <ul>Overnight stay in Kandy.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Dambulla"].Path_1} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Pinnawala"].Path_2} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                    <h3>Day 6</h3>
                  </div>

                  <ul>After breakfast, visit Pinnawala Elephant Orphanage, where is notable for having the largest herd of captive elephants in the world and get into Elephant dung paper factory to visit how to make elephant dung papers.</ul>
                  <ul>Visit Peradeniya Royal Botanical Gardens which houses a fine collection of over 4000 species of exotic flora plants.</ul>
                  <ul>Overnight stay in Kandy.</ul>
                  
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 7</h3>
                </div>

                <ul>After Breakfast travel to Dambana(Mahiyanganaya)</ul>
                <ul>Visit indeginous people in Sri Lanka calling Veddah.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Packages["Prestine"].Img2} alt=""></img>
              </GridItem>
              
              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Packages["Tea"].Img2} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                    <h3>Day 8 & 9</h3>
                  </div>

                  <ul>After breakfast, travel to deep of tea plantations.</ul>
                  <ul>En route visit a tea factory – to discover how the most widely consumed drink in the world is prepared.</ul>
                  <ul>Visit Gregory Lake, the most prominent attraction in Old English town. If you like to have a boat ride, Horse Ride. When you walk on the lane in the city, you may feel the Ancient English colonial era.</ul>
                  <ul>Overnight stay in Hill Country of Nuwara Eliya.</ul>
              </GridItem>
              
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 10</h3>
                </div>

                <ul>Early in the morning travel to Horton Plains National Park which is a key wildlife area of open grassy woodland and World’s end with Bakers water fall. Collect more photographs of flora & fauna with many more endemic spices.</ul>
                <ul>Get in to train tour to see nice attractions from highest railway station to Ella.</ul>
                <ul>Overnight stay in Ella.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Horton_Plains"].Path_1} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={nineArch} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 11</h3>
                </div>

                <ul>After breakfast vist nine arch Bridge, Ella gap and Rawana Waterfall whish was relative to prehistoric legend.</ul>
                <ul>Proceed to Southern part of country.</ul>
                <ul>Enroute visit Buduruwagala huge granite rock carvings.</ul>
                <ul>Drive to Yala and overnight stay in Yala</ul>
                  
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 12</h3>
                </div>

                <ul>Early in the morning start for the game to visit wild animals in deep at Yala national park. You may see Elephants, Bears, Monkeys, Buff lows, Dears, Many kind of beards and Leopard in this dry zone jungle.</ul>
                <ul>Overnight stay in Yala.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Yala"].Path_2} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Mirissa"].Path_1} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 13</h3>
                </div>

                <ul>After breakfast drive to Mirissa.</ul>
                <ul>Mirissa is famous for Whales and Dolphin watching.Main whales watching season start from december till March.</ul>
                <ul>Overnight stay in Mirissa.</ul>
                  
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 14</h3>
                </div>

                <ul>Early in the morning check out from hotel to Whales and Dolphin watching in Mirissa. This is a famous deep sea area to see Blue whales.</ul>
                <ul>Travel to Galle.</ul>
                <ul>En route visit Unawatuna Beach, which is the one of most beautiful beach in the world.</ul>
                <ul>Get in to sea Turtle hatchery and visit the Galle fort.</ul>
                <ul>Overnight stay at beach resort in Benthota.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Galle"].Path_2} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Packages["Around"].Img1} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 15</h3>
                </div>

                <ul>Benthota is famous for water activities at lagoon, river and sea.</ul>
                <ul>Leisure at the beach resort in Benthota.</ul>
                <ul>Overnight stay at Benthota.</ul>
                  
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 16 & 17</h3>
                </div>

                <ul>•	Travel to the main economical city of Colombo after checkout from the hotel.</ul>
                <ul>•	Have a city tour in Colombo to see big shopping malls, Old parliament, Independence Squire, Galle face green, Baire lake and Gangarama temple.</ul>
                <ul>•	Overnight stay in Colombo.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Colombo"].Path_1} alt=""></img>
              </GridItem>

          </GridContainer>
          
          <div className={classes.space50} />
          
        </div>
        <TourPackages />
      </div>
    );
  }
}


export default withStyles(basicsStyle)(PristineSection);
