import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import Header from "../../components/Header/Header.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";

import HeaderLinks from "../../components/Header/HeaderLinks.jsx";
import Parallax from "../../components/Parallax/Parallax.jsx";

import landingPageStyle from "../../assets/jss/material-kit-react/views/landingPage.jsx";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import ButtonTheme from "../../components/CustomButtons/ButtonTheme.jsx";
import TourPackages from "../Components/Sections/TourPackages";
import Helmet from "react-helmet";

import { Things } from "../../assets/jss/Data";

import image1 from "../../assets/img/Home/2.jpg";

class ThingsToDo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      thing: null,
    };
    this.changething = this.changething.bind(this);
  }

  changething = (e) => {
    this.setState({ thing: e.target.value });
  };

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          brand=""
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 300,
            color: "dark",
          }}
          {...rest}
        />

        <Helmet>
          <meta charSet="utf-8" />
          <title>Things to do</title>
          <meta property="og:site_name" content="Summer Sri Lanka" />
          <meta
            property="og:description"
            content="The things can do in sri lanka. visit www.summersrilanka.com for more info "
          />
          <meta property="og:image" content={image1}></meta>
          <meta
            name="keywords"
            content="sri lanaka, tour, tourim, best tour planes, places to visit in sri lanka, tour sri lanka, tour, summer, summer in sri lanka, things can do in sri lanka"
          ></meta>
          <meta
            property="og:url"
            content="http://www.summersrilanka.com/things-to-do/"
          ></meta>
        </Helmet>

        {this.state.thing === null ? (
          <Parallax style={{ height: "500px" }} image={image1}>
            <div className={classes.container}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <h1 className={classes.title}>Things to do</h1>
                </GridItem>
              </GridContainer>
            </div>
          </Parallax>
        ) : (
          <Parallax
            style={{ height: "500px" }}
            image={Things[this.state.thing].Path_1}
          >
            <div className={classes.container}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  {/* <h1 className={classes.title}>{Things[this.state.thing].Topic}</h1> */}
                </GridItem>
              </GridContainer>
            </div>
          </Parallax>
        )}

        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            {this.state.thing === null ? (
              <GridContainer
                style={{ paddingTop: "100px", paddingBottom: "10px" }}
              >
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Things["Cycling"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Cycling and Mountain Biking in Sri Lanka
                        </Typography>
                        <button
                          class="card-button"
                          value="Cycling"
                          onClick={this.changething}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Things["Safari"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Tented Safari Camping in Sri Lanka
                        </Typography>
                        <button
                          value="Safari"
                          onClick={this.changething}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Things["Surfing"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Sri Lanka Surfin
                        </Typography>
                        <button
                          value="Surfing"
                          onClick={this.changething}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Things["Diving"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Diving
                        </Typography>
                        <button
                          value="Diving"
                          onClick={this.changething}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Things["Deep"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Deep Sea Fishing
                        </Typography>
                        <button
                          value="Deep"
                          onClick={this.changething}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Things["Yoga"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Yoga/ Meditation
                        </Typography>
                        <button
                          value="Yoga"
                          onClick={this.changething}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Things["Kite"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Kite Surfing
                        </Typography>
                        <button
                          value="Kite"
                          onClick={this.changething}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Things["Jungle"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Jungle Safaris
                        </Typography>
                        <button
                          value="Jungle"
                          onClick={this.changething}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Things["Bird"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Bird Watching
                        </Typography>
                        <button
                          value="Bird"
                          onClick={this.changething}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Things["Water"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          White Water Rafting
                        </Typography>
                        <button
                          value="Water"
                          onClick={this.changething}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Things["Cart"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Bullock Cart Riding in Sri Lanka
                        </Typography>
                        <button
                          value="Cart"
                          onClick={this.changething}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Things["Elephant"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Elephant Riding
                        </Typography>
                        <button
                          value="Elephant"
                          onClick={this.changething}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ paddingBottom: "80px" }}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: "200px" }}
                        image={Things["Hiking"].Path_1}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Hiking and Trekking
                        </Typography>
                        <button
                          value="Hiking"
                          onClick={this.changething}
                        ></button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>
              </GridContainer>
            ) : (
              <GridContainer
                style={{ paddingTop: "100px", paddingBottom: "100px" }}
              >
                <GridItem xs={12} sm={12} md={6}>
                  <h2 style={{ color: "black" }}>
                    {Things[this.state.thing].Topic}
                  </h2>
                  <p style={{ color: "black" }}>
                    {Things[this.state.thing].Description}
                  </p>
                  <ButtonTheme color="primary" href="/things-to-do">
                    Back
                  </ButtonTheme>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <img
                    className="themeImage"
                    src={Things[this.state.thing].Path_2}
                    alt=""
                  ></img>
                </GridItem>
              </GridContainer>
            )}
          </div>

          <div style={{ paddingBottom: "50px" }}>
            <TourPackages />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(landingPageStyle)(ThingsToDo);
