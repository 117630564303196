import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { MessengerChat } from "react-messenger-chat-plugin";

// @material-ui/icons

// core components
import Header from "../../components/Header/Header.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import SectionCarousel from "../Components/Sections/SectionCarousel";
import SectionBasics from "../Components/Sections/SectionBasics";

import HeaderLinks from "../../components/Header/HeaderLinks.jsx";
import landingPageStyle from "../../assets/jss/material-kit-react/views/landingPage.jsx";
import Helmet from "react-helmet";
import Logo from "../../assets/img/logo.png";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          brand=""
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 200,
            color: "dark",
          }}
          {...rest}
        />

        <Helmet>
          <meta charSet="utf-8" />
          <title>Summer Sri Lanka</title>
          <meta property="og:site_name" content="Summer Sri Lanka" />
          <meta
            property="og:description"
            content="Summer Sri Lanka is the best way to make arrange your holiday vacation. We promise to give the best value for your money. Our chauffeurs and guides have specialized knowledge to make unforgettable memories in the miracle island."
          />
          <meta property="og:image" content={Logo}></meta>
          <meta
            name="keywords"
            content="sri lanaka, tour, tourim, best tour planes, places to visit in sri lanka, tour sri lanka, tour, summer, summer in sri lanka, things can do in sri lanka"
          ></meta>
          <meta
            property="og:url"
            content="http://www.summersrilanka.com/"
          ></meta>
        </Helmet>

        <SectionCarousel />

        <div className={classNames(classes.main, classes.mainRaised)}>
          <SectionBasics />
        </div>

        <MessengerChat
          pageId="1692973634346105"
          language="en_US"
          themeColor={"#000000"}
          bottomSpacing={300}
          loggedInGreeting="Welcome to Summer Sri Lanka"
          loggedOutGreeting="Have a nice day!"
          greetingDialogDisplay={
            "Hi! Thanks for getting in touch with us on Messenger. Please send us any questions you may have."
          }
          debugMode={true}
        />

        <Footer />
      </div>
    );
  }
}

export default withStyles(landingPageStyle)(HomePage);
