/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// core components
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown.jsx";
import Button from "../../components/CustomButtons/Button.jsx";

import headerLinksStyle from "../../assets/jss/material-kit-react/components/headerLinksStyle.jsx";

function HeaderLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.list}>

      <ListItem className={classes.listItem}>
        <Button href="/" color="transparent" className={classes.navLink}>Home</Button>
        <Button href="/places-to-visit" color="transparent"  className={classes.navLink}>Places to visit</Button>
        <Button href="/things-to-do" color="transparent" className={classes.navLink}>Things To Do</Button>
      </ListItem>

      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Tour Packages"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
         
          dropdownList={[ 
            <Link to="/around-sri-lanka" className={classes.dropdownLink}> Around Sri Lanka</Link>,
            <Link to="/essence-sri-lanka" className={classes.dropdownLink}> Essence Sri Lanka</Link>,
            <Link to="/explore-sri-lanka" className={classes.dropdownLink}> Explore Sri Lanka</Link>,
            <Link to="/heritage-tour" className={classes.dropdownLink}> Heritage Tour</Link>,
            <Link to="/pristine-sri-lanka" className={classes.dropdownLink}> Pristine Sri Lanka</Link>,
            <Link to="/scenic-sri-lanka" className={classes.dropdownLink}> Scenic Sri Lanka</Link>,
            <Link to="/tea-trail" className={classes.dropdownLink}> Tea Trail</Link>,
            <Link to="/contact-us" className={classes.dropdownLink}> More</Link>,
            
          ]}
        />
      </ListItem>

      <ListItem className={classes.listItem}>
        <Button href="/travel-tips" color="transparent"  className={classes.navLink}>Travel Tips</Button>
        <Button href="/guest-feedback" color="transparent" className={classes.navLink}>Guest Feedback</Button>
        <Button href="/contact-us" color="transparent" className={classes.navLink}>Contact Us</Button>
      </ListItem>

      {/* Social-media-links */}
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/summersrilanka/"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
      
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Follow us on instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/summersltours"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </ListItem>

    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
