import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";

import {Places} from '../../../assets/jss/Data';
import nineArch from "../../../assets/img/Home/7.jpg";


import TourPackages from "../../Components/Sections/TourPackages";

import basicsStyle from "../../../assets/jss/material-kit-react/views/componentsSections/basicsStyle.jsx";

class AroundSection extends React.Component {
 
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sections}>

        <div className={classes.container}>
          <div className={classes.space20} />

          <GridContainer>
              <GridItem xs={12} sm={12} md={6}>

                <div className={classes.title}>
                  <h3>Day 1</h3>
                </div>

                <ul>Meet and assist our guide on Arrival. </ul>
                <ul>Drive to visit the fishing village and Ancient Dutch fort in Negombo. Get a boat in the evening to ride over lagoon.</ul>
                <ul>Leisure and Overnight stay, beach resort in Negombo.</ul>
                
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Negombo"].Path_1} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Pinnawala"].Path_2} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 2</h3>
                </div>

                <ul>After breakfast travel to central province through Pinnawala.</ul>
                <ul>Visit Pinnawala Elephant Orphanage, where is notable for having the largest herd of captive elephants in the world and get into Elephant dung paper factory to visit how to make elephant dung papers.</ul>
                <ul>Overnight stay in Sigiriya / Habarana.</ul>
                 
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>

                <div className={classes.title}>
                  <h3>Day 3</h3>
                </div>

                <ul>After breakfast leave to Sigiriya to see 650ft high rock fortress and world famous frescos painting of heavenly maidens are housed in a cave, which is declared as a World Heritage site by UNESCO. It was the largest and most sophisticated single construction in 5th century AD.</ul>
                <ul>When you are at Sigiriya you can see traditional colourful painting of Batik and Silk.</ul>
                <ul>Travel to Polonnaruwa,was the 2nd kingdom in ancient Sri Lanka in 2nd century BC. </ul>
                <ul>In the Evening you can have a relaxation and body massage from an Ayurvedic Spa.</ul>
                <ul>Overnight stay in Sigiriya.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Sigiriya"].Path_1} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Anuradhapura"].Path_2} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                    <h3>Day 4</h3>
                  </div>

                  <ul>After breakfast travel to Anuradhapura, The First Capital of Sri Lanka in the 3rd century BC to visit the first Buddhist Temple of Isurumiuniya, situated close to the Tisa Wawa. Which is famous for its stone carvings of the ‘Isurumuniya Lovers’ .</ul>
                  <ul>There is much to visit at Anuradhapura, including the sacred tree of Sri Maha Bodhi: the oldest historical documented tree in the world (2500 years old), and eight major places with ancient Buddhist monasteries.</ul>
                  <ul>In the evening take a jeep for wild life safari at Minneriya National park.</ul>
                  <ul>Overnight stay in Habarana.</ul>
                  
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>

                <div className={classes.title}>
                  <h3>Day 5</h3>
                </div>

                <ul>After breakfast leave to Kandy.</ul>
                <ul>En route visit a spice and ayurwedic herbal garden at Mathale to see how to make Sri Lankan natural cosmetics and herbal medicine.</ul>
                <ul>In Kandy, have a city tour in last kingdom of Sri Lanka.</ul>
                <ul>Visit the Temple of the Sacred Tooth Relic which is the most famous place in Buddhist’s world.</ul>
                <ul>In the evening you can visit spectacular cultural dancing show and fire walk.</ul>
                <ul>Overnight stay in Kandy.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Kandy"].Path_1} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Nuwara_Eliya"].Path_2} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                    <h3>Day 6</h3>
                  </div>

                  <ul>After breakfast, travel to deep of tea plantations.</ul>
                  <ul>En route visit a tea factory – to discover how the most widely consumed drink in the world is prepared.</ul>
                  <ul>Visit Gregory Lake, the most prominent attraction in Old English town. If you like to have a boat ride, Horse Ride. When you walk on the lane in the city, you may feel the Ancient English colonial era.</ul>
                  <ul>Overnight stay in Hill Country of Nuwara Eliya.</ul>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 7</h3>
                </div>

                <ul>After breakfast travel to Thissamaharama, the closer place calling Yala famous for wild life safari in Sri Lanka.</ul>
                <ul>En-route visit Ravana water fall and Ella Gap in attraction view.</ul>
                <ul>Relax and overnight stay in Thissamaharama.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={nineArch} alt=""></img>
              </GridItem>
              
              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Yala"].Path_2} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                    <h3>Day 8</h3>
                  </div>

                  <ul>Early in the morning start the game to drive in dry zone jungle by off road jeep.</ul>
                  <ul>You may visit Bears, boars, peacock, crocodile, Elephants, leopard and many kind of animals.</ul>
                  <ul>Overnight stay in Thissamaharama.</ul>
              </GridItem>
              
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 9, 10 and 11</h3>
                </div>

                <ul>After breakfast travel to Benthota through Galle.</ul>
                <ul>Visit Unawatuna beach and Galle fort.</ul>
                <ul>Benthota is famous for water activities at lagoon, river and the sea.</ul>
                <ul>Leisure at the beach resort in Benthota.</ul>

                </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Galle"].Path_2} alt=""></img>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
              <img className="themeImage" src={Places["Colombo"].Path_1} alt=""></img>
              </GridItem>
              <GridItem  xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Day 12</h3>
                </div>

                <ul>Travel to the main economical city of Colombo after checkout from the hotel.</ul>
                <ul>Have a city tour in Colombo to see big shopping malls, Old parliament, Independence Squire, Galle face green, Baire lake and Gangarama temple.</ul>
                <ul>Overnight stay in Colombo.</ul>
                  
              </GridItem>

          </GridContainer>
          
          <div className={classes.space50} />
          
        </div>
        <TourPackages />
      </div>
    );
  }
}


export default withStyles(basicsStyle)(AroundSection);
