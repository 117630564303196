import axios from "axios";

export const sendEmail = async (details) => {
  try {
    const res = await axios.post(
      "https://api.emailjs.com/api/v1.0/email/send",
      {
        service_id: "service_dyza769",
        template_id: "template_nc5juff",
        user_id: "Bm7Cxt4kEuTxUBFcS",
        template_params: details,
      }
    );
  } catch (error) {
    console.log("Failed", error);
  }
};
``;
