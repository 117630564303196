import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import Typography from "@material-ui/core/Typography";

import basicsStyle from "../../../assets/jss/material-kit-react/views/componentsSections/basicsStyle.jsx";

class SectionBasics3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sections}>
        <div className={classes.container}>
          <div className={classes.space20} />

          <GridContainer>
            <GridItem xs={12} sm={12} md={9}>
              <Card style={{ padding: "20px" }}>
                <CardBody>
                  <Typography gutterBottom variant="h5" component="h2">
                    Best memory ever
                  </Typography>
                  <h5>
                    We went to Lanka in Feb.2015. Cha is a really nice and
                    friendly person and have introduced us a lot about Sri
                    Lanka. We are really addicted to this place and we don’t
                    even want to leave. People are so adorable here and the
                    scenery is so attractive. I really look forward to visiting
                    it again soon. Also, thousands thanks to our best guide
                    wasanta.
                  </h5>
                  <h4>
                    <b>Wynn Leong – China</b>
                  </h4>
                </CardBody>
              </Card>

              <Card style={{ padding: "20px" }}>
                <CardBody>
                  <Typography gutterBottom variant="h5" component="h2">
                    Best Choice
                  </Typography>
                  <h5>
                    We spent 2 weeks travelling around Sri Lanka from 3rd
                    October to 16th October with Mr. nilantha ratnayaka as a
                    very good driver and a professional guide. He was all the
                    time prepared and able to help us and to recommend some
                    interesting places or accommodation. He has a lot of
                    experience. He organized for us very nice safari in
                    Udawalawe NP and recommended ancient temples near Kandy.
                  </h5>
                </CardBody>
              </Card>

              <Card style={{ padding: "20px" }}>
                <CardBody>
                  <Typography gutterBottom variant="h5" component="h2">
                    难忘的斯里兰卡跨年游
                  </Typography>
                  <h5>
                    我们两家人在15年的过年期间,来到了这个东南亚小岛国旅游说实话来之前没有期望那么高，总想着会很艰苦，但行程结束之后，我们全部人只有同一个反应就是流连忘返，回味连连！淳朴的民风人情，可爱的当地人笑容，热带水果，霍顿平原，大象孤儿院等等等等。总之非常值得回味的一次旅游！趁着斯里兰卡尚未商业化之前应该再去一次！
                  </h5>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={3}>
              <div className={classes.title}>
                <h3>Contact us</h3>
                <br />

                <h4>Mr. Vasantha Pradeep</h4>
                <h5>
                  #788/1/1, Peradeniya Road, Getambe, Kandy, Sri Lanka 20000
                </h5>
                <h5>summersltours@gmail.com</h5>
                <h5>www.summersrilanka.com</h5>
                <h5>Tp: +94 755 800808 | +94 728 416416</h5>
              </div>
            </GridItem>
          </GridContainer>

          <div className={classes.space50} />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(SectionBasics3);
