import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import tabsStyle from "../../../assets/jss/material-kit-react/views/componentsSections/tabsStyle.jsx";
import Small from "../../../components/Typography/Small.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";

class TourPackages extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div className="tour">
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <h2>
                  Tour Packages
                  <br />
                  <Small>Choose the best package suits for you..!</Small>
                </h2>

                <Button color="primary" href="packages">
                  Packages
                </Button>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <h2>
                  Plan Your Tour Now..!
                  <br />
                </h2>

                <Button color="primary" href="/contact-us">
                  Inquire
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(tabsStyle)(TourPackages);
